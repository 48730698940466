import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["groupingTemplate", "groupingContainer"]

    static values = {
        objectName: String
    }

    addNestedConditionGroup(event) {
        const newId = new Date().getTime()

        event.detail.target.insertAdjacentHTML(
            'beforeend',
            this.renderTemplate(newId, event.detail.objectName)
        )
    }

    addConditionGroup(event) {
        const newId = new Date().getTime();

        this.groupingContainerTarget.insertAdjacentHTML(
            'beforeend',
            this.renderTemplate(newId, this.objectNameValue)
        );
    }

    renderTemplate(groupingId, objectName) {
        const content = this.groupingTemplateTarget.innerHTML;

        return content
            .replace(/new_grouping/g, groupingId)
            .replace(/new_object_name/g, objectName);
    }
}