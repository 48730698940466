import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['conditionTemplate', 'groupingContainer']

    static values = {
        objectName: String
    }

    addConditionGroup() {
        // This gets handled by AdvancedSearchController#addNestedConditionGroup
        // via a data-action on the controller element, because ConditionGroupController
        // doesn't have access to the template element.
        this.dispatch("addNestedConditionGroup", {
            detail: {
                objectName:  this.objectNameValue,
                target: this.groupingContainerTarget,
            }
        })
    }

    deleteGroup() {
        this.element.remove();
    }

    deleteCondition(event) {
        const objectName = event.target.dataset.objectName;
        this.groupingContainerTarget.querySelector(`[data-object-name='${objectName}']`).remove()
    }

    addCondition() {
        const content = this.conditionTemplateTarget.innerHTML;
        const newId = new Date().getTime();

        this.groupingContainerTarget.insertAdjacentHTML(
            'beforeend',
            content.replace(/new_condition/g, newId)
        );
    }
}