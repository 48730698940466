import { Controller } from '@hotwired/stimulus'
import { JSONEditor } from "vanilla-jsoneditor"

export default class extends Controller {
    static targets = ["documents", "editor"]

    connect() {
        super.connect()
    }

    editorTargetConnected(target) {
        const json = JSON.parse(this.documentsTarget.innerHTML);

        this.editor = new JSONEditor({
            target,
            props: {
                content: {json},
                // readOnly: true,
                mode: "table"
            }
        });
    }
}