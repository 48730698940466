import "@hotwired/turbo-rails"
Turbo.start()

// import ActiveStorage from "@rails/activestorage"
// ActiveStorage.start()

import { library, icon } from '@fortawesome/fontawesome-svg-core';
import {
  faTrash,
  faPlay,
  faCopy,
  faPlus,
  faX,
  faPenToSquare,
  faCircleExclamation,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faCircleExclamation,
  faCopy,
  faPenToSquare,
  faPlay,
  faPlus,
  faSpinner,
  faTrash,
  faX,
);

window.HQ_ICONS = {
    ...window.HQ_ICONS,
    circleExclamation: icon({ prefix: 'fas', iconName: 'circle-exclamation' }).html[0],
    copy:              icon({ prefix: 'fas', iconName: 'copy' }).html[0],
    pencil:            icon({ prefix: 'fas', iconName: 'pen-to-square' }).html[0],
    play:              icon({ prefix: 'fas', iconName: 'play' }).html[0],
    plus:              icon({ prefix: 'fas', iconName: 'plus' }).html[0],
    spinner:           icon({ prefix: 'fas', iconName: 'spinner' }).html[0],
    trash:             icon({ prefix: 'fas', iconName: 'trash' }).html[0],
    x:                 icon({ prefix: 'fas', iconName: 'x' }).html[0],
}

import '@enginuitygroup/library'
import "./application.scss"
import "../controllers"

const channels = import.meta.glob("../**/*_channel.js", { eager: true })
