import { Controller } from "@hotwired/stimulus"
import { toggle as toggleTransition } from "el-transition"

export default class extends Controller {
   static targets = ["toggler", "content"]
   static values = { revealed: Boolean }

  toggle(event) {
    this.contentTargets.forEach(toggleTransition)
    this.revealedValue = !this.revealedValue
    this.togglerTarget.innerText = (this.revealedValue ? event.params.less : event.params.more)
   }
}
