import { Controller } from "@hotwired/stimulus"
import { enter, leave} from "el-transition"

export default class extends Controller {
  static targets = [ "modal", "overlay", "autofocus" ]

  initialize() {
    this.modalOpen = false
  }

  show() {
    Promise.all([enter(this.modalTarget), enter(this.overlayTarget)]).then(() => {
      this.modalOpen = true

      if (this.hasAutofocusTarget) {
        this.autofocusTarget?.focus()
      }
    })
  }

  hide() {
    Promise.all([leave(this.modalTarget), leave(this.overlayTarget)]).then(() => {
      this.element.removeAttribute("src")
      this.element.firstElementChild.remove()
      this.modalOpen = false
    })
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.hide()
    }
    else {
      console.error(e.detail)
    }
  }

  closeWithKeyboard(e) {
    if (e.code === "Escape" && this.modalOpen) {
      this.hide()
    }
  }

  // Once we move these events to a target inside the frame, this becomes possible.
  // click@window->modal#closeBackground
  closeBackground(e) {
    if (e && this.modalTarget.contains(e.target)) {
      return
    }
    this.hide()
  }
}
