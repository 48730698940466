import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = [ "visibility", "openButton" ]

  initialize() {
    this.menuOpen = false
  }

  toggle() {
    this.menuOpen ? this.hideMenu() : this.showMenu()
    this.menuOpen = !this.menuOpen
    console.log({ menuOpen: this.menuOpen })
  }

  showMenu() {
    this.visibilityTargets.forEach((target) => { enter(target) })
  }

  hideMenu() {
    this.visibilityTargets.forEach((target) => { leave(target) })
  }
}
